import React, { useEffect, useState, useCallback } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Breadcrumb from "../components/Global/Breadcrumb";
import htmlDecode from "html-entities-decoder";
import ImageViewer from "react-simple-image-viewer";

import { format } from "date-fns";
import { el, enGB } from "date-fns/locale";

import User from "./../images/user.svg";
import Calendar from "./../images/calendar.svg";
import promoBanner from "../images/mpesstodiktio-banner-1-300x300.png";
import folderIcon from "../images/folder-regular.svg";

// import your share buttons or other dependencies as needed
// import "../components/Blog/PostTemplate.css";

function BlogPostTemplate(props) {
  const data = props.data;
  const post = data.allWpPost.nodes[0]; // This is the single post

  console.log(props)
  // You can see if there's an array of available language versions
  let availableVersionsFiltered = props.pageContext.availablePages || [];

  // Example code adjusting the availableVersionsFiltered array:
  if (availableVersionsFiltered[0]) {
    const tmp = `${
      availableVersionsFiltered[0].language.slug === "en"
        ? `/${availableVersionsFiltered[0].language.slug}`
        : ""
    }/blog/${availableVersionsFiltered[0].slug}`;
    availableVersionsFiltered[0].uri = tmp;
  }

  // Image viewer states
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [customOpener, setCustomOpener] = useState("");

  // When an image is clicked, set which one we want to open
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  // On mount, gather any gallery images and attach click handlers
  useEffect(() => {
    const isBrowser = typeof window !== "undefined";

    if (isBrowser) {
      // If we have an 'extraGallery' from WP, use that
      const extraGallery = post?.extraGallery?.gallery || [];

      if (extraGallery.length > 0) {
        // Use the provided extraGallery
        let tmpGallery = extraGallery.map((imgObj) => imgObj.image?.sourceUrl);
        setImages(tmpGallery);
      } else {
        // Otherwise, try to scrape from .blocks-gallery-item elements
        var elements = document.getElementsByClassName("blocks-gallery-item");

        function handleGalleryClick(e) {
          if (e.target?.src) {
            setCustomOpener(e.target.src);
          }
        }

        let tmpGallery = [];
        for (let i = 0; i < elements.length; i++) {
          elements[i].addEventListener("click", handleGalleryClick, false);

          // Attempt to locate the image source in nested children
          let possibleSrc =
            elements[i]?.querySelector("img")?.currentSrc ||
            elements[i]?.querySelector("img")?.src;

          if (possibleSrc) {
            tmpGallery.push(possibleSrc);
          }
        }

        setImages(tmpGallery);
      }
    }
  }, [post]);

  // When customOpener changes (someone clicked an image), open the viewer
  useEffect(() => {
    if (customOpener !== "") {
      openImageViewer(images.indexOf(customOpener));
    }
  }, [customOpener, images, openImageViewer]);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  /**
   * Construct meta data for the single post.
   * We'll build a proper canonical for the single post’s URL
   * using language if needed.
   */
  function constructMetaData(singlePost, language) {
    if (!singlePost?.seo) return {};
    console.log(singlePost)
    const title = singlePost.title;

    const {
      metaDesc,
      opengraphTitle,
      opengraphDescription,
      opengraphImage,
    } = singlePost.seo;

    // If you have multi-language, adjust the prefix or domain as needed:
    const baseUrl = "https://dkaa.gr";
    const isEnglish = language === "EN";
    const langPrefix = isEnglish ? "/en" : "";
    // Build a canonical that points to your single post
    const canonical = `${baseUrl}${langPrefix}/blog/${singlePost.slug}/`;

    return {
      title: title,
      description: "",
      canonical: "",
      fbTitle: opengraphTitle || title,
      fbDescription:  "",
      fbUrl: canonical,
      fbType: "website",
      locale: isEnglish ? "en" : "el", // or use your logic
      image: opengraphImage?.sourceUrl || "",
    };
  }

  // Build meta data from the single post
  const metaData = constructMetaData(
    post,
    props.pageContext.language // 'EN' or 'EL'
  );

  return (
    <Layout
      headerMenu={props.pageContext.headerMenu}
      footerMenu={props.pageContext.footerMenu}
      recentPosts={data.related}
      currentPage={{
        currentPage: props.pageContext.currentPage,
        language: props.pageContext.languageName,
      }}
      availableVersions={availableVersionsFiltered}
      metaData={metaData}
      services={props.pageContext.servicesCategories}
      preHeader={data.allWpTemplate.nodes[0].contactInformationTemplate}
    >
      {/* Breadcrumb if needed. We can pass h1=post title, or keep as is */}
      <section>
        <Breadcrumb h1="" />
      </section>

      {/* MAIN ARTICLE */}
      <article
        className="container mx-auto mt-16 px-4 flex flex-wrap"
        style={{ maxWidth: "1170px" }}
      >
        <div className="w-full pb-0 lg:pb-8">
          {/* FEATURED IMAGE */}
          <div className="sp-img-container w-full relative">
            {post?.featuredImage?.node?.sourceUrl && (
              <img
                src={post.featuredImage.node.sourceUrl}
                alt={post.featuredImage.node.altText || ""}
              />
            )}
          </div>

          {/* TITLE */}
          <div>
            <h1 className="bread-h1" style={{ lineHeight: "1.6" }}>
              {htmlDecode(post?.title || "")}
            </h1>
          </div>

          {/* DATE, AUTHOR, ETC. (optional) */}
          {/* 
          <div
            className="flex blog-info py-6 mb-4"
            style={{ borderBottom: "1px solid rgba(0,0,0,0.08)" }}
          >
            <div className="flex justify-center">
              <div>
                <img
                  src={Calendar}
                  style={{ width: "15px", marginRight: "5px" }}
                  alt="calendar icon"
                />
              </div>
              <div>
                {
                  // If Greek or English date format:
                  props.pageContext.language === "EL"
                    ? format(new Date(post.date), "dd MMMM, yyyy", { locale: el })
                    : format(new Date(post.date), "dd MMMM, yyyy", {
                        locale: enGB,
                      })
                }
              </div>
            </div>
          </div>
          */}

          {/* POST CONTENT */}
          <div>
            <div className="sp-content-wrapper">
              {/* The post content from WP */}
              <div
                id="post-editor"
                dangerouslySetInnerHTML={{ __html: post.content }}
              />

              {/* If there's a manual 'extraGallery' with images, show them */}
              {post.extraGallery?.gallery && post.extraGallery.gallery.length > 0 && (
                <ul className="flex flex-wrap pt-4 pb-8">
                  {post.extraGallery.gallery.map((img2, i) => {
                    const src = img2?.image?.sourceUrl;
                    return (
                      <li
                        className="w-full md:w-1/2 lg:w-1/3 pr-4 py-2"
                        key={`img-gallery-key-${i}`}
                      >
                        {src && (
                          <img
                            onClick={(e) => setCustomOpener(e.target.src)}
                            src={src}
                            alt={img2?.image?.altText || ""}
                            style={{ borderRadius: "5px", cursor: "zoom-in" }}
                          />
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
        </div>

        {/* SIDEBAR, BANNERS, ETC. (Optionally reintroduce if needed)
        <div className="w-full lg:w-1/3 pl-0 lg:pl-8 py-8 lg:py-0">
          <div className="relative">
            ...
          </div>
          <div className="mt-8">
            <Link
              to={
                props.pageContext.language === "EL"
                  ? "/become-member"
                  : "/en/become-member-en"
              }
            >
              <img src={promoBanner} alt="Promo banner" />
            </Link>
          </div>
        </div>
        */}
      </article>

      {/* IMAGE VIEWER MODAL */}
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
        />
      )}
    </Layout>
  );
}

export default BlogPostTemplate;

/**
 * GraphQL query:
 *  - Pulls the single post by $id
 *  - Also grabs a specific Page if needed for breadcrumbAcf, etc.
 *  - Also queries your template data for the pre-header
 *  - Pulls 2 'related' posts
 */
export const pageQuery = graphql`
  query BLOG_POST_QUERY(
    $id: String
    $language: WpLanguageCodeEnum
    $themeTemplateUri: String
  ) {
    allWpPage(filter: { id: { eq: "cG9zdDo1MDYz" } }) {
      nodes {
        breadcrumbAcf {
          breadcrumbName
        }
      }
    }
    allWpPost(filter: { id: { eq: $id } }) {
      nodes {
        slug
        title
        content
        date
        author {
          node {
            name
            firstName
            avatar {
              url
            }
          }
        }
        seo {
          title
          metaDesc
          opengraphDescription
          opengraphTitle
          opengraphImage {
            sourceUrl
          }
        }
        featuredImage {
          node {
            sourceUrl
            altText
          }
        }
        extraGallery {
          gallery {
            image {
              sourceUrl
              altText
            }
          }
        }
      }
    }
    allWpTemplate(filter: { slug: { eq: $themeTemplateUri } }) {
      nodes {
        contactInformationTemplate {
          telephoneNumber
          supportEmail
          hoursLabel
          address {
            addressGoogleLink
            addressLabel
          }
          hoursTitle
          form {
            title
            subTitle
            fullName
            email {
              label
              errorMessage
            }
            subject
            message {
              label
              errorMessage
            }
            telephone {
              label
              errorMessage
            }
            sendButton
          }
          responseMessages {
            success
            failed
          }
        }
      }
    }
    related: allWpPost(limit: 2, filter: { language: { code: { eq: $language } } }) {
      ...RecentPostsFragment
    }
  }
`;
